/*
BASIC SANS WEIGHTS: 200, 300, 400, 600, 700
VENICE BLVD WEIGHTS: 400, 600, 700, 900

*/

/* GENERAL STYLING */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  width: 100%;
  height: 100vh;
}

body {
  font-family: basic-sans, sans-serif;
  background-color: #F7F7F5;
  width: 100%;
}

li {
  list-style-type: none;
}

:root {
  --border: .5px solid black;
  --padding: .75rem;
  --corner-rounding: 5px;
  --form-corner-rounding: 3px;
  --yellow: #FAEDCB;
  --green: #C9E4DE;
  --blue: #C6DEF1;
  --red: #FFD2D2;
  --gray: #ECF0F1;

}

.yellow {
  background-color: var(--yellow);
}

.green {
  background: var(--green);
}

.blue {
  background: var(--blue);
}

.red {
  background: var(--red);
}

.gray {
  background: var(--gray);
}

.white {
  background: white;
}

/* LOADING SCREEN AND LOADING ERROR */
.load-error {
  transform: translateX(-50%);
  left: 50%;
  top: 30%;
  padding: calc(2*var(--padding));
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  color: #c0392b;
  border: var(--border)
}

.load-error-heading {
  font-weight: 600;
}

.loading-note {
  width: 300px;
}

.loading-note-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding-top: calc(2 * var(--padding));
  padding-bottom: calc(2 * var(--padding));
}

.loading-note-title {
  font-size: 1rem;
}

/* NAVBAR */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background: white;
  position: relative;
  padding-left: calc(2*var(--padding));
  padding-right: calc(2*var(--padding));
  border-bottom: var(--border);
}

.nav-title {
  font-family: venice-blvd, sans-serif;
}

.nav-title:hover {
  cursor: pointer;
}

.nav-item {
  color: black;
  text-decoration: none;
}

.nav-item:hover {
  cursor: pointer;
}

.nav-right-content {
  display: flex;
  gap: 1rem;
}

/* NOTES */
.note {
  position: absolute;
  background: white;
  border-radius: var(--corner-rounding);
  box-shadow: 4px 4px black;
  overflow: hidden;
}

.note-top {
  display: flex;
  justify-content: space-between;
  padding: var(--padding) calc(var(--padding) * 1.5);
  align-items: center;
}

.note-title {
  font-size: 1rem;
  font-weight: 600;
  transform: translateY(10%);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 1rem;
}

.note-icon {
  flex-shrink: 0;
}

.note-text-container {
  padding: calc(var(--padding) * 1.5);
  text-align: left;
}

.note-text {
  font-size: .9rem;
  font-weight: 200;
  line-height: 1.25rem;
  white-space: pre-wrap;
}

/* CENTERED DIALOG, FOR LOGIN AND SIGNUP AND MORE */
.center-note {
  border: var(--border);
  left: 50%;
  top: 10%;
  transform: translateX(-50%);
  animation: noteSlideUp .5s;
}

.center-note-top {
  display: flex;
  justify-content: center;
  border-bottom: var(--border);
  padding-top: var(--padding);
  padding-bottom: var(--padding);
}

/* INPUT FORM, FOR LOGIN AND SIGNUP AND MORE */
.vertical-form {
  display: flex;
  flex-direction: column;
  gap: var(--padding)
}

.form-label {
  display: inline-block;
  margin-bottom: .25rem;
}

.form-redirect-text {
  font-size: 1rem;
  color: black;
  display: inline-block;
  margin-top: 1rem;
}

.form-input {
  background: white;
  padding: .5rem;
  border: var(--border);
  border-radius: var(--form-corner-rounding);
}

.block-input {
  display: block;
  width: 300px;
}

.auto-input {
  width: 100%
}

.form-input:focus {
  outline: var(--border);
}

/* BUTTONS */
.btn {
  font-family: basic-sans, sans-serif;
  font-size: 1rem;
  outline: none;
  font-weight: 500;
  padding: .4rem;
  border-radius: var(--form-corner-rounding);
}

.btn-standard {
  background: white;
  border: var(--border);
}

.btn-100 {
  width: 100%;
  margin-top: var(--padding);
}

.btn-standard:hover {
  background: rgb(243, 243, 243);
}

.btn-delete {
  background: rgb(255, 240, 240);
  color: red;
  border: .5px solid red;
}

.btn-delete:hover {
  background: rgb(252, 235, 235);
}


/* DIALOGUE OVERLAY */
.overlay {
  background: black;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  opacity: .5;
}


/* FORM RESPONSES MESSAGES */
.response {
  margin-top: var(--padding);
  padding: var(--padding);
  border-radius: var(--form-corner-rounding);
}

.error {
  color: red;
  border: .5px solid red;
  background: rgb(255, 240, 240);
}

.success {
  color: green;
  border: .5px solid green;
  background: rgb(235, 255, 238);

}

/* ABOUT DIALOG */
.about-dialog {
  width: 600px;
  font-weight: 300;
  font-size: 1rem;
}

.about-title {
  font-size: 1.15rem;
}

.about-close {
  font-size: 1.5rem;
  cursor: pointer;
}

.about-dialog:focus {
  outline: none;
}

.about-text-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.about-heading {
  font-size: 1rem;
}

.about-top {
  border-bottom: var(--border);
}

/* NEW NOTE DIALOG */
.new-note-dialog {
  display: inline-block;
}

.new-note-textarea {
  min-width: 400px;
  min-height: 150px;
}

/* ACCOUNT DIALOG */
.account-note {
  width: 350px;
}


/* DROPDOWN MENU - MOSTLY STYLES FROM RADIX UI */
.DropdownMenuContent {
  min-width: 150px;
  background-color: white;
  border-radius: var(--corner-rounding);
  padding: 5px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  animation: slideUpAndFade .4s cubic-bezier(0.16, 1, 0.3, 1);
}

.DropdownMenuItem {
  font-size: .85rem;
  border-radius: var(--corner-rounding);
  display: flex;
  align-items: center;
  padding: .3rem;
  padding-left: 20px;
  user-select: none;
  outline: none;
}

.DropdownMenuItem:hover {
  background: rgb(244, 244, 244);
}

.DropdownMenuSeparator {
  height: .5px;
  background-color: black;
  margin: 5px;
}

.DropdownMenuArrow {
  fill: white;
}

.dropdown-delete-trigger {
  font-family: basic-sans, sans-serif;
  background: white;
  outline: none;
  border: none;
  font-size: .85rem;
  width: 100%;
}

/* SEARCH BAR */
.search {
  width: 99%;
  height: 20px;
  padding: 15px;
  margin: 10px;
  outline: none;
  position: absolute;
  bottom: 0;
}

/* PLUS ICON FOR NEW NOTE */
.plus {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 60px;
  right: 10px;
  transition: .25s ease;
}

.plus:hover {
  transform: scale(1.2);
}

/* FONT STYLING FOR INPUTS */
input,
input[type=text],
textarea[type=text] {
  font-family: basic-sans, sans-serif;
  font-weight: 200;
  font-size: .9rem;
}

/* PUTS TWO BUTTONS NEXT TO EACH OTHER */
.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: .25rem;
  margin-top: var(--padding);
}

/* COLOR SELECTOR IN NEW NOTE DIALOG - MOSTLY FROM RADIX UI*/
.ToggleGroup {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-bottom: .5rem;
}

.ToggleGroupItem {
  height: 35px;
  border: none;
}

.ToggleGroupItem:focus {
  outline: none
}

.ToggleGroupItem:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.ToggleGroupItem:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ToggleGroupItem[data-state='on'] {
  border: .5px solid black;
}

/* CROSS INSIDE COLOR SELECTOR */
.cross {
  transform: translateY(10%);
  color: red;
  height: 22px;
  width: 22px;
}

/* POP UP ALERT ON DELETE ACCOUNT */
.alert {
  background-color: white;
  border-radius: var(--corner-rounding);
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  padding: 25px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  border: var(--border);
}

.AlertDialogContent:focus {
  outline: none;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes noteSlideUp {
  from {
    opacity: 0;
    transform: translate(-50%, 50px);
  }

  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@keyframes aboutSlide {
  from {
    transform: translate(-50%, -40%);
    opacity: 0;
  }

  to {
    transform: translate(-50%, -50%);
    opacity: 100;
  }
}